import Head from "next/head";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import ToResolveClassNameError from "../components/hoc/ToResolveClassNameError";
import HomepageWrapper from "../components/homepage/HomepageWrapper/HomepageWrapper";
import SharedFooter from "../components/shared/SharedFooter/SharedFooter";
import * as config from "../config/config";
import { getUser } from "../redux/actions/user_action";

function Home(props) {
  const router = useRouter();
  const cUser = getUser();

  let metaTitle = "Kerja Kosong Terkini di Malaysia - NaikGaji";

  let metaDescription =
    "Cari peluang pekerjaan yang sesuai dengan pengalaman dan kemahiran anda. Jom naik gaji!";

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta property="og:url" content="https://naikgaji.com" key="ogUrl" />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={config.assetDomain + "/images/logo/naikgaji-meta-img.png"}
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://naikgaji.com"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={config.assetDomain + "/images/logo/naikgaji-meta-img.png"}
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href="https://naikgaji.com" />

        {/* iOS Smart App Banner */}
        {/* <meta
          name="apple-itunes-app"
          content="app-id=981491669, app-argument=https://my.hiredly.com/"
        ></meta> */}
      </Head>
      <ToResolveClassNameError>
        <HomepageWrapper />
        {props.showMobileSearch ? null : (
          <Fragment>
            <SharedFooter />
          </Fragment>
        )}
      </ToResolveClassNameError>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
